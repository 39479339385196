import axios from "axios";
// const BASE_URL = 'http://localhost:3000';
const BASE_URL = 'https://buketochka-server.herokuapp.com';

export default {

    //Изначальное состояние
    state: {
        cartList: [],
        cartAmount: 0,
        cartPositions: 0,
        storageCartList: []
    },


    mutations: {

        addProductToCart(state, cartItem) {

            let index = state.cartList.findIndex(o => o.productId === cartItem.productId)

            if (index !== -1) {
                state.cartList[index].amount = cartItem.amount
                state.cartList[index].sum = cartItem.amount * cartItem.price
            } else {
                state.cartList.push(cartItem)
            }

            state.storageCartList = state.cartList;
            localStorage.setItem('cart', JSON.stringify(state.storageCartList))

        },
        setCart(state, payload) {
            state.cartList = payload;
            state.storageCartList = payload;
            localStorage.cart = JSON.stringify(payload);
        },
        clearCart(state) {
            state.cartList = state.storageCartList = [];
            localStorage.removeItem('cart')
        },

        deleteProductFormCart(state, cartItem) {
            let i = state.cartList.findIndex(o => o.productId === cartItem.productId);
            state.cartList.splice(i, 1);
            state.storageCartList = state.cartList;
            localStorage.setItem('cart', JSON.stringify(state.storageCartList))
        },

        incProductCount(state, product) {
            let item = state.cartList.find(val => val.productId === product.productId);
            item.amount++;
            item.sum = item.amount * item.price
            state.storageCartList = state.cartList;
            localStorage.setItem('cart', JSON.stringify(state.storageCartList))

        },

        decProductCount(state, product) {
            let item = state.cartList.find(val => val.productId === product.productId);
            item.amount--;
            item.sum = item.amount * item.price
            state.storageCartList = state.cartList;
            localStorage.setItem('cart', JSON.stringify(state.storageCartList))

        },
    },
    actions: {
        syncCart({ commit }) {
            const cart = JSON.parse(localStorage.getItem('cart'))
            if (cart && cart.length) {
                commit('setCart', cart)
            }
        },
        async newOrder({ commit }, newOrder) {
            try {
                return await axios.post(BASE_URL + '/newOrder', {
                    data: newOrder
                },
                    { timeout: 10000 })
            } catch (e) {
                commit('setError', e);
                throw e;
            }
        },
    },

    getters: {
        // Геттер списка с товарами в корзине
        getCart(state) {
            return state && state.cartList || []
        },
        gerCartSum(_, getters) {
            return getters.getCart.reduce((sum, product) => {
                return sum += Number(product.sum)
            }, 0)
        },
        gerCartAmount(_, getters) {
            return getters.getCart.reduce((amount, product) => {
                return amount += Number(product.amount)
            }, 0)
        }
    }
}