<template>
     <nav class="navbar grey darken-4 ">
            <div class="nav-wrapper ">
                <div class="navbar-left">

                    <!-- ПЕРЕДАЕМ КЛИК ПО КНОПКЕ НАВБАРА В МЕЙН  -->
                <a href="#" @click.prevent="$emit('click')">
                    <i class="material-icons  white-text ">dehaze</i>
                </a>
                    <!--                    ПРИМЕНЯЕМ ФИЛЬТР-->

                </div>

                <ul class="right white-text">
                <li>
                    <a
                        class="dropdown-trigger white-text darken-2"
                        href="#"
                        data-target="dropdown"
                        ref="dropdown"
                    >
                        Выйти
                    <i class="material-icons right">arrow_drop_down</i>
                    </a>

                    <ul id='dropdown' class='dropdown-content'>
                    <li>
                        <router-link  to="/" class="black-text">
                        <i class="material-icons">web</i>Смотреть сайт
                        </router-link>
                    </li>

                    <li class="divider" tabindex="-1"></li>

                    <li>
                        <a href="#" class="black-text"  @click.prevent="logout">
                        <i class="material-icons">assignment_return</i>Выйти из системы
                        </a>
                    </li>
                    </ul>
                </li>
                </ul>
            </div>
        </nav>
</template>

<script>
    export default {
        data: () =>({
            date : new Date(),
            interval: null,
            dropdown: null
        }),
        methods: {
            async logout(){
                await this.$store.dispatch('logout');
                console.log('выход из системы');
                this.$router.push('/login?message=logout');
            }
        },
        mounted() {
            this.interval = setInterval( ()=>{
                this.date = new Date();
            }, 1000);
            this.dropdown = M.Dropdown.init(this.$refs.dropdown, {
                constrainWidth: true,
            });
        },

        computed:{
            name() {
                return this.$store.getters.info.name
            }
        },


        beforeDestroy() {
            clearInterval(this.interval);
            if (this.dropdown && this.dropdown.destroy){
                this.dropdown.destroy();
                console.log('destroy')
            }

        }
    }
</script>