import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import info from './info'
import category from "./category";
import products from './products'
import cart from './cart'
import shared from './shared'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: null,
  },

  mutations: {
    setError(state, error){
      state.error = error;
    },
    clearError(state) {
      state.error = null
    }
  },


  actions: {},

  modules:{
    auth, info, category, products, cart, shared
  }
})
