import firebase from "firebase/app";
export default {
    
    state:{
        info:{},
    },

    mutations:{
        setInfo(state, info){
            state.info = info;
        },
        clearInfo(state){
            state.info = {}
        }
    },

    actions:{

        async fetchInfo({dispatch, commit}){
            try{
                const info = (await firebase.database().ref(`/info`).once('value')).val()
                commit('setInfo', info)
            }catch (e) {
                commit('setError', e)
                throw e
            }
        },

        
    },
    getters:{
        info: s => s.info,
    }
}