<template>

    <div>

    </div>
</template>

<script>
    export default {
        data: () => ({
            name: 'footer'

        }),
    }
</script>

<style scoped lang="less">
    @mobileMenuBreakpoint: 650px;
    @text-color: rgb(26,26, 24);
    @white: rgb(255,255, 255);
    @red: #f35858;
    @black: #343434;


</style>