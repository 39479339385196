import firebase from 'firebase/app'

export default {

    //Изначальное состояние
    state : {
        productsList: [],
        product: {},
        filteredProducts: [],
    },

    actions: {
        // загружаем категории
        async createProduct({commit, dispatch}, product) {
            try {

                const mainImg = product.mainImage;
                const images = product.otherImages;

                const mainImageExt = mainImg.name.slice(mainImg.name.lastIndexOf('.'))


                const newProduct = {
                    title : product.title,
                    amount: product.amount,
                    price : product.price,
                    description: product.description,
                    parentCategory: product.parentCategory,
                    subCategories : product.subCategories,
                    mainImageSrc:'',
                    otherImagesSrc: []
                };
                const createdProduct =   await firebase.database().ref(`/products`).push(newProduct)


                const mainFileData =  await firebase.storage().ref(`productsImages/${createdProduct.key}${mainImageExt}`).put(mainImg);
                const mainImageSrc = await mainFileData.ref.getDownloadURL();

                const otherImages = [];

                async function f() {
                    for (let img of images) {
                        let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
                        let otherImagesExt = await img.name.slice(img.name.lastIndexOf('.'));
                        let otherFileData =  await  firebase.storage().ref(`productsImages/${rand}${createdProduct.key}${otherImagesExt}`).put(img);
                        let otherImageSrc = await otherFileData.ref.getDownloadURL();
                        otherImages.push(otherImageSrc);
                    }
                    return  otherImages
                }

                await f().finally(() => {
                    console.log(' RETURN fileData otherImages', mainFileData, otherImages);
                });

                await firebase.database().ref(`/products`).child(createdProduct.key).update({
                 otherImages, mainImageSrc
                });

                return createdProduct

            } catch (e) {
                commit('setError', e)
                throw e
            }
        },

        async fetchProducts({ dispatch, commit}){
            try {
                const products = (await firebase.database().ref(`/products`).once('value')).val() || {};
                // пробегаемся по объекту рекорд, получаем айди каждого рекорда, возвращаем весь рекорд и получаем массив
                const temp = Object.keys(products).map(key => ({...products[key], id: key}))

                commit('setProducts', temp);
                commit('setLoading', false);
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },

        async fetchSingleProductById({ dispatch, commit}, id){
            try {
                return (await firebase.database().ref(`/products`).child(id).once('value')).val() || {}
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },

        async fetchSingleProductByIdStore({ state, commit}, id){
            try {
                const temp =  state.productsList.find(val => val.id === id );
                commit('setProduct', temp);
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },

        async fetchProductsByCategoryId({ dispatch, commit}, CategoryId) {


        }
    },

    mutations: {
        setProducts(state, payload){
 
            state.productsList = payload;
        },

        filterProducts(state, payload) {
            state.filteredProducts = state.productsList.filter(p => p.subCategories.find(s => s.Path === payload))
        },

        setProduct(state, payload){
            state.product = payload;
        },

    },


    getters: {

        getSubCatProducts: s => s.filteredProducts,
        //Метод для получения списка с товарами в корзине
        getProducts(state) {
            return state.productsList
        },

        getProduct(state) {
            return state.product
        }

    }
}