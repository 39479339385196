import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from "vuelidate";
import store from './store';
import './registerServiceWorker'
import currencyFilter from "./filters/currency.filter";
import messagePlugin from './myUtils/message.plugin';
import 'materialize-css/dist/js/materialize.min';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import VueWaypoint from 'vue-waypoint'
import VueMask from 'v-mask'


import Loader from "./components/app/Loader";
import FullScreenLoader from "./components/app/FullScreenLoader";

Vue.use(VueMask);
Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(messagePlugin);
Vue.use(VueWaypoint)
Vue.filter('currency', currencyFilter);
Vue.component('Loader', Loader);
Vue.component('FullScreenLoader', FullScreenLoader);

const firebaseConfig = {
  apiKey: "AIzaSyCkHYoTq2wERDV8EpVvQbBNolziCPiE6SM",
  authDomain: "buketochka-34cc1.firebaseapp.com",
  databaseURL: "https://buketochka-34cc1.firebaseio.com",
  projectId: "buketochka-34cc1",
  storageBucket: "buketochka-34cc1.appspot.com",
  messagingSenderId: "889641042564",
  appId: "1:889641042564:web:87a0b5e302e3c3e726b8d1",
  measurementId: "G-HWEV3Z80GQ"
};

firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
