import router from "../router";

export default {
    install(Vue, options){
        Vue.prototype.$message = function (html) {
                M.toast({html})
        };

        Vue.prototype.$error = function (html) {
            M.toast({html: `Ошибка! : ${html}`});

        }

        Vue.prototype.$addToCart = function () {
            const toastHTML = `<div style="font-weight: 500; letter-spacing:1px; font-size: 18px; "
            > 
            Товар добавлен в корзину</div>`

            M.toast({html: toastHTML, classes:'pa1, grey darken-3'});

        };


        Vue.prototype.$changeQuantity = function () {
            const toastHTML = `<div style="font-weight: 500; letter-spacing:1px; font-size: 18px; "
            > 
            Количество измененено 
            </div>`

            M.toast({html: toastHTML, classes:'pa1, grey darken-3'});

        };

    }

}