<template>
	<div>
		<transition name="fade">
			<FullScreenLoader v-if="loading" />

			<div v-else class="app-main-layout ">
				<Navigation :info="info" />
				<Header :info="info" />
				<main class="main-pages">
					<transition name="main-pages">
						<router-view :info="info" />
					</transition>
				</main>
				<Footer />
			</div>
		</transition>
	</div>
</template>

<script>
import Header from '@/components/MainPageSections/Header';
import Navigation from '../components/app/Navigation';
import Footer from '../components/app/Footer';

export default {
	name: 'main-layout',
	data: () => ({
		info: {},
		categories: [],
		loading: true,
		img: false,
	}),
	components: {
		Navigation,
		Footer,
		Header,
	},

	async mounted() {
		try {
			this.loading = true;
			await this.$store.dispatch('fetchInfo');
			this.info = this.$store.getters.info;
			this.$store.dispatch('fetchProducts');
			this.$store.dispatch('syncCart');
		} catch (error) {
			console.error({ error });
		} finally {
			this.loading = false;
		}
	},
};
</script>
