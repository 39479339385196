<template>
    <div class="empty-layout">
        <router-view />
    </div>
</template>


<script>
    import messages from "@/myUtils/messages";

    export default {
        name: 'empty-layout',
        computed: {
            error() {
                // если чтото будет меняться в геттерах, то у нас будет обновляться это компьютед свойство
                return this.$store.getters.error
            },
        },
        watch:{
            error(fireBaseError){
                console.log(fireBaseError);
                this.$error(messages[fireBaseError.code] || ' Что-то пошло не так ');
            }
        }
    }
</script>

<style lang="less">
    .empty-layout{
        padding-top:2rem;
        height:100vh;

        display:flex;
        -webkit-flex-direction:column;
        -ms-flex-direction:column;
        flex-direction:column;
        -webkit-align-items:center;
        -ms-flex-align:center;
        align-items:center;

    }

</style>


