import firebase from 'firebase/app'

export default {
    actions: {
        // загружаем категории
        async fetchCategories({commit, dispatch}) {
            try {

                const categories = (await firebase.database().ref(`/categories`).once('value')).val() || {}
                //  Object.keys(categories).forEach(key => (
                //      cats.push({
                //          title: categories[key].title,
                //          limit: categories[key].limit
                //      })
                //  ));
                //
                const temp = Object.keys(categories).map(key => ({...categories[key], id: key}))
                commit('setCategories', temp);
                return temp
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },
        async fetchCategoryById({commit, dispatch}, id) {
            try {
                const userId = await dispatch('getUserId')
                const category = (await firebase.database().ref(`/users/${userId}/categories`).child(id).once('value')).val() || {}
                return {...category, id}
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },
        async updateCategory({commit, dispatch}, {title, path, id}) {
            try {

                await firebase.database().ref(`/categories`).child(id).update({title, path})
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },

        async deleteCategory({commit, dispatch}, {id}) {
            try {
                await firebase.database().ref(`/categories`).child(id).remove()
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },
        async createCategory({commit, dispatch}, {title, path}) {
            try {

                const category = await firebase.database().ref(`/categories`).push({title, path})
                return {title, path, id: category.key}
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },

        async createSubcategory({commit, dispatch}, {title, path, id}) {
            try {

                const category = await firebase.database().ref(`/categories/${id}/subcategories`).push({title, path})
                return {title, path, id: category.key}
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },

        async updateSubCategory({commit, dispatch}, {title, path, id, sid}) {
            try {

                await firebase.database().ref(`/categories/${id}/subcategories`).child(sid).update({title, path})
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },

        async deleteSubCategory({commit, dispatch}, {id, sid}) {
            try {

                await firebase.database().ref(`/categories/${id}/subcategories`).child(sid).remove()
            } catch (e) {
                commit('setError', e)
                throw e
            }
        },
    },
    //Изначальное состояние
    state : {
       categories: [],
       subCategories: []
    },


    mutations: {
        setCategories(state, payload){
            state.categories = payload;
        },
        setSubCategories(state, payload){
            state.subCategories = payload;
        },

    },

    getters: {
        //Метод для получения списка с товарами в корзине
        getCategories(state) {
            return state.categories
        },


        getSubCategories: state => route => {
            let title = ''
            state.categories.forEach(cat => {
                Object.keys(cat.subcategories).map(key => {
                     if(cat.subcategories[key].path === route) {
                         title = cat.title + ' ' + cat.subcategories[key].title
                     }
                })
            })
            return title 
        }



    }
}