import Vue from 'vue'
import Router from 'vue-router'
import firebase from "firebase/app";
Vue.use(Router);

const  router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

    {
      path: '/login',
      name: 'login',
      meta: {layout: 'empty'},
      component: () => import('./views/empty/Login.vue')
    },

    {
      path: '/',
      name: 'home',
      meta: {layout: 'main'},
      component: () => import('./views/main/Home.vue')
    },

    {
      path: '/bouquets',
      name: 'bouquets',
      component: () => import('./views/main/Bouquets.vue'),
      meta: {layout: 'main'},
    },

    {
      path: '/sets',
      name: 'sets',
      meta: {layout: 'main'},
      component: () => import('./views/main/Sets.vue'),
    },

    {
      path: '/cart',
      name: 'cart',
      meta: {layout: 'main'},
      component: () => import('./views/main/Cart.vue')
    },

    {
      path: '/checkout',
      name: 'checkout',
      meta: {layout: 'main'},
      component: () => import('./views/main/Checkout.vue')
    },






    {
      path: '/product/:id',
      props: true,
      name: 'product',
      meta: {layout: 'main'},
      component: () => import('./views/main/ProductPage.vue')
    },


    {
      path: '/admin',
      name: 'admin',
      meta: {layout: 'admin', auth: true},

      component: () => import('./views/admin/Home.vue'),

      children: [

        {
          path: 'categories',
          name: 'adminCategories',
          meta: {layout: 'admin', auth: true},
          component: () => import('./views/admin/Categories'),
        },
        {
          path: 'subcategories',
          name: 'adminSubcategories',
          meta: {layout: 'admin', auth: true},
          component: ()=> import('./views/admin/SubCategories'),
        },
        {
          path: 'products',
          name: 'adminProducts',
          meta: {layout: 'admin', auth: true},
          component: ()=> import('./views/admin/Products'),
        },
      ]
    },

    {
      path: '/:subcategory',
      name: 'subcategory',
      meta: {layout: 'main'},
      props: true,
      params: true,
      component: () => import('./views/main/SubcategoryComponent.vue')
    },
  ]
})

router.beforeEach((to, from, next)=>{
  const currentUser  = firebase.auth().currentUser
  const requireAuth = to.matched.some(record => record.meta.auth)
  if(requireAuth && !currentUser){
    next('/login')
  } else {
    next()
  }

})

export  default  router
