import firebase from "firebase/app";

export default {
    actions:{
        async login({dispatch, commit}, {email, password}) {
            try {
                await firebase.auth().signInWithEmailAndPassword(email, password)
            } catch (e) {
                commit('setError', e);

                throw e;
            }
        },
        async logout({commit}) {
            await  firebase.auth().signOut();
            //чистим экшн после логаута
            commit('clearInfo')
        },

        async register({dispatch, commit}, {email, password, name}) {
            try {
                await firebase.auth().createUserWithEmailAndPassword(email, password);
                const userId = await  dispatch('getUserId');
                await firebase.database().ref(`/users/${userId}/info`).set({
                    name: name,
                    ordersCount: 0,
                    ordersAmount: 0
                })
            } catch (e) {
              commit('setError', e);
                throw e;
            }
        },

        getUserId(){
            const user = firebase.auth().currentUser;
            // если такой пользователь есть, то возвращаем его айди
            return user ?  user.uid :  null;
        }
    }
}