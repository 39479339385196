<template>
  <div id="app">

    <component :is="layout">
      <transition name="layout" mode="out-in">
          <router-view />
      </transition>
    </component>


  </div>
</template>

<script>
  import EmptyLayout from './layouts/EmptyLayout'
  import MainLayout from './layouts/MainLayout'
  import AdminLayout from './layouts/AdminLayout'

  export default {
    computed: {
      layout(){
        return (this.$route.meta.layout || 'empty' || 'admin') + '-layout';
      }
    },
    components:{
      EmptyLayout, MainLayout, AdminLayout
    },
    created() {
    }
  }
</script>


<style lang="less">
  @import '~materialize-css/dist/css/materialize.min.css';
  @import 'assets/index.css';

</style>
