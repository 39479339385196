<template>

  <!-- динамическое добавление класса open из мейнлеаут. значние приходит как props а в мейне v-model   -->
     <ul class="sidenav app-sidenav" :class="{open: value}">
      
      
      <router-link v-for="link in links" :key="link.url"
        tag="li" active-class="active"
        :to="link.url"
        :exact="link.exact"
        >

        <a href="#" class="waves-effect waves-red pointer">{{link.title}}</a>
      </router-link>

    </ul>
</template>


<script>
export default {
  props: ['value'],
    data: () =>({
      links: [
        {title: 'Главная', url: '/admin', exact : true},
        {title: 'Категории', url: '/admin/categories', },
        {title: 'Подкатегории', url: '/admin/subcategories',},
        {title: 'Товары', url: '/admin/products',},
      ]
    })  
}
</script>