<template>

    <header class="center center-block">
        <div class="header">
            <div class="row header-row">

                <div class="col s12 m4 l4 xl4  logotype">
                    <router-link :to="'/'">
                        <img class="responsive-img" src="../../../public/img/logos/buketochka_logo.jpeg" alt="логотип компании buketochka">
                    </router-link>
                </div>

                <div class="col s12 m4 l4 xl4 center description">
                    <h4> Букеты и наборы из клубники в бельгийском шоколаде	</h4>
                </div>
              
                <div class="col s12 m4 l4 xl4 phone_number">

                    <a :href='info.phone' class="phone">
                             {{info.mainPhone}}
                        <i class="material-icons">phone_enabled</i>
                    </a>

                    <div class="social-line">
                        <a :href='info.telegram'  class="telegram-href">
                            <i class="fab fa-telegram"></i>
                        </a>
                        <a :href='info.instagram' class="instagram-href">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a :href='info.whatsapp'  class="whatsapp-href">
                            <i class="fab fa-whatsapp"></i>
                        </a>
                    </div>


                </div>

            </div>

        </div>

    </header>

</template>

<script>
export default {
    props: {
        info: {
            required: true,
            type: Object,
        }
    },
}
</script>




<style scoped lang="less">
    @mobileMenuBreakpoint: 800px;
    @text-color: rgb(26,26, 24);
    @white: rgb(255,255, 255);
    @red: #f35858;
    @black: #343434;

    @media screen and (max-width: 360px) {


        .header-row{
            padding:  1rem 0 .0rem 0;
            margin-bottom: 0;
            box-shadow: 4px 2px 26px rgba(107, 103, 104, 0.48), 0 0 6px #FFFFFF;
        }

        .header{
            z-index: 100;
            position: relative;

            .logotype{
                img{
                    padding-left: 2%;
                    float: left;
                    margin-top: 0.8rem;
                    width: 220px;
                    &:hover{
                        transition: all 0.6s;
                        text-decoration: none;
                        opacity: 0.9;
                    }
                }
            }
            .description{
                h4{
                    color: #3c3c3c;

                    letter-spacing: 1px;
                    line-height: 30px;
                    font-weight: 500;
                    font-size: 15px;
                }
            }
            .phone_number{
                display: none;
            }
        }
    }

    @media screen  and (min-width: 360px) and (max-width: 600px) {

 
        .header{
            width: 100%;
            z-index: 100;

            position: relative;


            .header-row{
                padding:  .7rem 0 .0rem 0;
                margin-bottom: 0;
                box-shadow: 4px 2px 26px rgba(107, 103, 104, 0.48), 0 0 6px #FFFFFF;
            }
            .logotype{

                img{
                    padding-left: 2%;
                    float: left;
                    margin-top: 0.8rem;
                    width: 260px;
                    &:hover{
                        transition: all 0.6s;
                        text-decoration: none;
                        opacity: 0.9;
                    }
                }
            }
            .description{
                float: left;
                padding-left: 5%;
                text-align: left;
                h4{
                    color: #3c3c3c;
                    width: 68%;
                    letter-spacing: 1px;
                    line-height: 30px;
                    font-weight: 500;
                    font-size: 15px;
                }
            }
            .phone_number{
                display: none;
            }
        }
    }

    @media screen and (max-width:800px)   and (min-width: 601px){
        .header{
            width: 100vw;
            z-index: 100;
            box-shadow: 4px 2px 26px rgba(107, 103, 104, 0.48), 0 0 6px #FFFFFF;
            position: relative;
            padding:  1.6rem 0 1rem .8rem;

            .logotype{

                margin-top: .4rem;
                img{
                    width: 200px!important;
                    &:hover{

                        transition: all 0.6s;
                        text-decoration: none;
                        opacity: 0.9;
                    }
                }
            }
            .description{
                h4{
                    color: #343434;
                    font-weight: 500;
                    margin-top: 0.5rem;
                    font-size: 18px;
                    width: 50vw;
                }
            }
            .phone_number{
                display: none;
            }
        }
    }

    @media screen and (min-width: 801px) and (max-width: 1000px) {
        .header{
            left: 0;
            width: 100vw;
            position: absolute;
            top: 0;
            margin: 0;

            .header-row{
                position: relative;
                width: 100%;
                margin: 0;
                height: 9rem;
                padding: 1rem 1rem 1rem 2rem;
               
               .logotype{
                    img{
                        margin-top: 1.5rem;
                        width: 220px!important;
                        &:hover{
                            transition: all 0.6s;
                            text-decoration: none;
                            opacity: 0.9;
                        }
                    }
                }
                .description{
                    margin-top: .5rem;
                    text-transform: uppercase;
                    h4{
                        padding-left: 30px;
                        text-align: center;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
                        font-size: 14px;
                        color: #343434;
                        font-weight: 500;
                    }
                }
                
                .phone_number{
                    
                    margin-top: 2.1rem;
                    height: 30px;
                    position: relative;
                    padding-left: 1rem;
                    text-align: left;
                    
                    .phone{
                        left: 0;
                        position: relative;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
                        font-size: 1rem;
                        color: #343434;
                        font-weight: 500;
                        text-transform: uppercase;
                        height: 30px;
                        -webkit-transition: all .5s ease-in-out;
                        -moz-transition: all .5s ease-in-out;
                        -o-transition: all .5s ease-in-out;
                        -ms-transition: all .5s ease-in-out;
                        transition: all .5s ease-in-out;
                        
                        i{    
                            box-shadow: 0px 0px 10px rgba(52, 52, 52, 0.15);
                            top: -5px;
                            position: absolute;
                            margin-left: .6rem;
                            padding-left: 4px;
                            height: 25px;
                            line-height: 25px;
                            width: 25px;
                            border-radius: 50px;
                            color: #343434;
                            font-weight: 400;
                            font-size: 16px;
                        }

                        &:hover{
                            -webkit-transition: all .5s ease-in-out;
                            -moz-transition: all .5s ease-in-out;
                            -o-transition: all .5s ease-in-out;
                            -ms-transition: all .5s ease-in-out;
                            transition: all .5s ease-in-out;
                            text-decoration: none;
                            opacity: 0.7;
                            i{
                                -webkit-transition: all .4s ease-in-out;
                                -moz-transition: all .4s ease-in-out;
                                -o-transition: all .4s ease-in-out;
                                -ms-transition: all .4s ease-in-out;
                                transition: all .4s ease-in-out;
                                transform: rotate(360deg);
                            }
                        }
                    }

                    .social-line{
                        position: absolute;
                        top: 0px;
                        left: 12rem;
                        display: flex;
                        flex-direction: row;
                        width: 50px;
                       
                       &::after{
                            content: '  ';
                            display: block;
                            position: absolute;
                            top: 4px;
                            width: .13rem;
                            height: .8rem;
                            left:7px;
                            background-color:  #313131;
                                
                        }
                        .whatsapp-href, .telegram-href, .instagram-href {
                            position: relative;
                            width: 15px;
                            margin-left: 17px;      
                            color: #343434;   
                            font-size: 20px;
                            bottom: 5px;
                            &:hover{
                                -webkit-transition: all .5s ease-in-out;
                                -moz-transition: all .5s ease-in-out;
                                -o-transition: all .5s ease-in-out;
                                -ms-transition: all .5s ease-in-out;
                                transition: all .5s ease-in-out;
                                transform: scale(1.1);
                            }
                        }

                    }
                   

                }
            }
        }
    }

    @media screen and (min-width: 1001px) and (max-width: 1200px){
        .header{
            left: 0;
            width: 95vw;
            position: absolute;
            top: 0;
            margin: 0;
            .header-row{
                position: relative;
                margin: 0;
                height: 9rem;
                padding: 1rem 2rem 1rem 2rem;

                .logotype{

                    img{
                        margin-top: 1.5rem;

                        width: 240px!important;
                        &:hover{
                            transition: all 0.6s;
                            text-decoration: none;
                            opacity: 0.9;
                        }
                    }
                }
                .description{
                    margin-top: .4rem;
                    
                    h4{
                        text-align: center;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
                        font-size: 1.1rem;
                        color: #343434;
                        font-weight: 500;
                        opacity: 0.9;
                    }
                }
                .phone_number{
                    
                    margin-top: 2.1rem;
                    height: 30px;
                    position: relative;
                    padding-left: 3rem;
                    text-align: left;
                    
                    .phone{
                        left: 0;
                        position: relative;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
                        font-size: 1rem;
                        color: #343434;
                        font-weight: 500;
                        text-transform: uppercase;
                        height: 30px;
                        -webkit-transition: all .5s ease-in-out;
                        -moz-transition: all .5s ease-in-out;
                        -o-transition: all .5s ease-in-out;
                        -ms-transition: all .5s ease-in-out;
                        transition: all .5s ease-in-out;
                        
                        i{    
                            box-shadow: 0px 0px 10px rgba(52, 52, 52, 0.15);
                            top: -5px;
                            position: absolute;
                            margin-left: .6rem;
                            padding-left: 4px;
                            height: 25px;
                            line-height: 25px;
                            width: 25px;
                            border-radius: 50px;
                            color: #343434;
                            font-weight: 400;
                            font-size: 16px;
                        }

                        &:hover{
                            -webkit-transition: all .5s ease-in-out;
                            -moz-transition: all .5s ease-in-out;
                            -o-transition: all .5s ease-in-out;
                            -ms-transition: all .5s ease-in-out;
                            transition: all .5s ease-in-out;
                            text-decoration: none;
                            opacity: 0.7;
                            i{
                                -webkit-transition: all .4s ease-in-out;
                                -moz-transition: all .4s ease-in-out;
                                -o-transition: all .4s ease-in-out;
                                -ms-transition: all .4s ease-in-out;
                                transition: all .4s ease-in-out;
                                transform: rotate(360deg);
                            }
                        }
                    }

                    .social-line{
                        position: absolute;
                        top: 0px;
                        left: 14rem;
                        display: flex;
                        flex-direction: row;
                        width: 50px;
                       
                       &::after{
                            content: '  ';
                            display: block;
                            position: absolute;
                            top: 4px;
                            width: .13rem;
                            height: .8rem;
                            left:7px;
                            background-color:  #313131;
                                
                        }
                        .whatsapp-href, .telegram-href, .instagram-href {
                            position: relative;
                            width: 15px;
                            margin-left: 17px;      
                            color: #343434;   
                            font-size: 20px;
                            bottom: 5px;
                            &:hover{
                                -webkit-transition: all .5s ease-in-out;
                                -moz-transition: all .5s ease-in-out;
                                -o-transition: all .5s ease-in-out;
                                -ms-transition: all .5s ease-in-out;
                                transition: all .5s ease-in-out;
                                transform: scale(1.1);
                            }
                        }

                    }
                   

                }
            }
        }
    }

     @media screen and (min-width: 1201px) and (max-width: 1500px){
        .header{
            left: 0;
            width: 95vw;
            position: absolute;
            top: 0;
            margin: 0;
            .header-row{
                position: relative;
                margin: 0;
                height: 9rem;
                padding: 1rem 2rem 1rem 2rem;

                .logotype{

                    img{
                        margin-top: 1.5rem;

                        width: 240px!important;
                        &:hover{
                            transition: all 0.6s;
                            text-decoration: none;
                            opacity: 0.9;
                        }
                    }
                }
                .description{
                    margin-top: .4rem;
                    
                    h4{
                        text-align: center;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
                        font-size: 1.2rem;
                        color: #343434;
                        font-weight: 500;
                        opacity: 0.9;
                    }
                }
                .phone_number{
                    
                    margin-top: 2.1rem;
                    height: 30px;
                    position: relative;
                    padding-left: 3rem;
                    text-align: left;
                    
                    .phone{
                        left: 0;
                        position: relative;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
                        font-size: 1rem;
                        color: #343434;
                        font-weight: 500;
                        text-transform: uppercase;
                        height: 30px;
                        -webkit-transition: all .5s ease-in-out;
                        -moz-transition: all .5s ease-in-out;
                        -o-transition: all .5s ease-in-out;
                        -ms-transition: all .5s ease-in-out;
                        transition: all .5s ease-in-out;
                        
                        i{    
                            box-shadow: 0px 0px 10px rgba(52, 52, 52, 0.15);
                            top: -5px;
                            position: absolute;
                            margin-left: .6rem;
                            padding-left: 4px;
                            height: 25px;
                            line-height: 25px;
                            width: 25px;
                            border-radius: 50px;
                            color: #343434;
                            font-weight: 400;
                            font-size: 16px;
                        }

                        &:hover{
                            -webkit-transition: all .5s ease-in-out;
                            -moz-transition: all .5s ease-in-out;
                            -o-transition: all .5s ease-in-out;
                            -ms-transition: all .5s ease-in-out;
                            transition: all .5s ease-in-out;
                            text-decoration: none;
                            opacity: 0.7;
                            i{
                                -webkit-transition: all .4s ease-in-out;
                                -moz-transition: all .4s ease-in-out;
                                -o-transition: all .4s ease-in-out;
                                -ms-transition: all .4s ease-in-out;
                                transition: all .4s ease-in-out;
                                transform: rotate(360deg);
                            }
                        }
                    }

                    .social-line{
                        position: absolute;
                        top: 0px;
                        left: 14rem;
                        display: flex;
                        flex-direction: row;
                        width: 50px;
                       
                       &::after{
                            content: '  ';
                            display: block;
                            position: absolute;
                            top: 4px;
                            width: .13rem;
                            height: .8rem;
                            left:7px;
                            background-color:  #313131;
                                
                        }
                        .whatsapp-href, .telegram-href, .instagram-href {
                            position: relative;
                            width: 15px;
                            margin-left: 17px;      
                            color: #343434;   
                            font-size: 20px;
                            bottom: 5px;
                            &:hover{
                                -webkit-transition: all .5s ease-in-out;
                                -moz-transition: all .5s ease-in-out;
                                -o-transition: all .5s ease-in-out;
                                -ms-transition: all .5s ease-in-out;
                                transition: all .5s ease-in-out;
                                transform: scale(1.1);
                            }
                        }

                    }
                   

                }
            }
        }
    }
    @media screen and (min-width: 1501px) {
        .header{
            left: 0;
            width: 95vw;
            position: absolute;
            top: 0;
            margin: 0;
            .header-row{
                position: relative;
                margin: 0;
                height: 9rem;
                padding: 1rem 2rem 1rem 2rem;
                .logotype{
                    img{
                        margin-left: 9%;
                        float: left;
                        margin-top: 1.5rem;
                        width: 230px;
                        &:hover{
                            transition: all 0.6s;
                            text-decoration: none;
                            opacity: 0.9;
                        }
                    }
                }
                .description{
                    margin-top: .4rem;
                    text-transform: uppercase;
                    h4{

                        text-align: center;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
                        font-size: 1.1rem;
                        color: #313131;
                        font-weight: 500;
                    }
                }
                .phone_number{
                    
                    margin-top: 2.1rem;
                    height: 30px;
                    position: relative;
                    padding-left: 3rem;
                    text-align: left;
                    
                    .phone{
                        left: 0;
                        position: relative;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
                        font-size: 1rem;
                        color: #343434;
                        font-weight: 500;
                        text-transform: uppercase;
                        height: 30px;
                        -webkit-transition: all .5s ease-in-out;
                        -moz-transition: all .5s ease-in-out;
                        -o-transition: all .5s ease-in-out;
                        -ms-transition: all .5s ease-in-out;
                        transition: all .5s ease-in-out;
                        
                        i{    
                            box-shadow: 0px 0px 10px rgba(52, 52, 52, 0.15);
                            top: -5px;
                            position: absolute;
                            margin-left: .6rem;
                            padding-left: 4px;
                            height: 25px;
                            line-height: 25px;
                            width: 25px;
                            border-radius: 50px;
                            color: #343434;
                            font-weight: 400;
                            font-size: 16px;
                        }

                        &:hover{
                            -webkit-transition: all .5s ease-in-out;
                            -moz-transition: all .5s ease-in-out;
                            -o-transition: all .5s ease-in-out;
                            -ms-transition: all .5s ease-in-out;
                            transition: all .5s ease-in-out;
                            text-decoration: none;
                            opacity: 0.7;
                            i{
                                -webkit-transition: all .4s ease-in-out;
                                -moz-transition: all .4s ease-in-out;
                                -o-transition: all .4s ease-in-out;
                                -ms-transition: all .4s ease-in-out;
                                transition: all .4s ease-in-out;
                                transform: rotate(360deg);
                            }
                        }
                    }

                    .social-line{
                        position: absolute;
                        top: 0px;
                        left: 14rem;
                        display: flex;
                        flex-direction: row;
                        width: 50px;
                       
                       &::after{
                            content: '  ';
                            display: block;
                            position: absolute;
                            top: 4px;
                            width: .13rem;
                            height: .8rem;
                            left:7px;
                            background-color:  #313131;
                                
                        }
                        .whatsapp-href, .telegram-href, .instagram-href {
                            position: relative;
                            width: 15px;
                            margin-left: 17px;      
                            color: #343434;   
                            font-size: 20px;
                            bottom: 5px;
                            &:hover{
                                -webkit-transition: all .5s ease-in-out;
                                -moz-transition: all .5s ease-in-out;
                                -o-transition: all .5s ease-in-out;
                                -ms-transition: all .5s ease-in-out;
                                transition: all .5s ease-in-out;
                                transform: scale(1.1);
                            }
                        }

                    }
                   

                }
            }
        }
    }
</style>