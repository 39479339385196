<template>
	<nav class="navigation">
		<div :class="{ open: isOpen }" class="navigation-div">
			<div
				:class="{ open: isOpen }"
				@click="isOpen = !isOpen"
				class="background-nav-list"
			></div>

			<ul class="navigation-list">
				<router-link
					class="item"
					tag="li"
					to="/"
					exact
					active-class="active"
				>
					<a class="parent-a" href="#" @click="anotherLink('/')">
						Главная</a
					>
				</router-link>

				<li v-if="loading" class="item">
					<a class="parent-a" href=""> Загрузка категорий...</a>
				</li>

				<router-link
					v-else
					v-for="c in categories"
					:key="c.id"
					tag="li"
					active-class="active"
					:bind="c.id"
					@click="categoryLink('/')"
					:to="{ path: c.path, name: c.path.substring(1) }"
					class="item"
				>
					<a class="parent-a"> {{ c.title }}</a>

					<ul class="submenu-div">
						<li
							v-for="s in c.subcategories"
							:key="s.id"
							:class="{ active: $route.path === s.path }"
							class="sub-item"
						>
							<a @click="subCategoryLink({ path: s.path })">
								{{ s.title }}
							</a>
						</li>
					</ul>
				</router-link>

				<router-link
					class="cart-item"
					:class="{ inCart: cartAmount }"
					tag="li"
					:to="{ path: '/cart', name: 'cart' }"
				>
					<a class="cart-a" @click="anotherLink('/')">
						<i class="material-icons icon ">add_shopping_cart</i>
						<div class="counter">
							<p>{{ cartAmount }}</p>
						</div>

						<span class="to-cart-span">В корзину</span>
						<i class=" to-cart-i material-icons"
							>keyboard_arrow_right</i
						>
					</a>
				</router-link>
				<div class="connect-nav center black-text">
					<a :href="info.instagram">
						<i class="fab fa-instagram"> </i>
					</a>

					<a :href="info.telegram">
						<i class="fab fa-telegram"></i>
					</a>

					<a :href="info.whatsapp">
						<i class="fab fa-whatsapp"></i>
					</a>
				</div>
			</ul>

			<div
				class="cart-counter"
				:class="{
					inCart:
						cartAmount &&
						this.$route.path !== '/cart' &&
						this.$route.path !== '/checkout' &&
						this.$route.name !== 'product',
				}"
			>
				<router-link
					to="/cart"
					tag="div"
					class="cart-counter-container"
				>
					<i class="material-icons ">shopping_basket</i>
					<span>{{ cartAmount }}</span>
				</router-link>
			</div>
		</div>

		<div
			@click="isOpen = !isOpen"
			:class="{ open: isOpen, mContainerCart: cartAmount }"
			class="menu-mobile-container"
		>
			<div :class="{ active: isOpen }" class="menu-mobile">
				<span class="menu-mobile-bar" />
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	props: {
		info: {
			required: true,
			type: Object,
		},
	},
	data: () => ({
		dropdown: null,
		isOpen: false,
		loading: true,
		categories: [],
	}),

	async created() {
		this.categories = await this.$store.dispatch('fetchCategories');
		this.categories.forEach((cat) => {
			if (cat.subcategories !== null) {
				cat.subcategories = Object.keys(
					cat.subcategories,
				).map((key) => ({ ...cat.subcategories[key], id: key }));
			}
		});
		this.loading = false;
	},

	computed: {
		cartAmount() {
			return this.$store.getters.gerCartAmount;
		},
	},

	watch: {
		isOpen() {
			if (this.isOpen && window.screen.width < 800) {
				document.documentElement.style.overflow = 'hidden';
			} else {
				document.documentElement.style.overflow = 'auto';
			}
		},
	},

	methods: {
		subCategoryLink(link) {
			window.scroll({
				top: 50,
				left: 0,
			});

			this.isOpen = false;

			if (this.$route.fullPath !== link) {
				this.$router.push(link);
			}
		},
		categoryLink() {
			window.scroll({
				top: 50,
				left: 0,
			});
		},

		anotherLink() {
			window.scroll({
				top: 0,
				left: 0,
			});
			this.isOpen = false;
		},

		toAdmin() {
			this.isOpen = false;
			document.documentElement.style.overflow = 'auto';
			document.documentElement.style.position = 'relative';
			this.$router.push('/admin/categories');
		},
	},
};
</script>

<style lang="less">
@mobileMenuBreakpoint: 800px;
@text-color: rgb(26, 26, 24);
@white: rgb(255, 255, 255);

/*desktop*/
@main-theme-color: #ff5c5c;
@parent-a: #ff5c5c;
@parent-a-active: #af4040;
@nav-color: #ff5c5c;
@child-a: #ff5c5c;

@main-theme-color-mobile: #ff5c5c;
@parent-a-mobile: #ff5c5c;
@parent-a-mobile-active: #af4040;
@nav-color-mobile: #ff5c5c;
@child-a-mobile: #fafafa;
@hover-submenu: #fafafa;

@black: #343434;
@mobileBarSize: 4rem;

/*===========================================ШИРИНА  600 ПИКСЕЛЕЙ==============================================*/
@media screen and (max-width: 600px) {
	.cart-counter {
		position: fixed;
		right: 3rem;
		bottom: 3rem;
		opacity: 0;
		transform: scale(0);
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		background-color: transparent;
		width: 35px;
		height: 20px;
		z-index: 700;
		border-radius: 50px;
		box-shadow: 0 0 55px black, 0 0 55px white;

		.cart-counter-container {
			border-radius: 50px;
			bottom: 25px;
			left: 15px;
			position: relative;
			box-shadow: 0 0 55px black;
			i {
				height: 80px;
				z-index: 750;
				top: 1px;
				right: 1px;
				color: #4b4b4b;
				font-size: 75px !important;
				position: absolute;
				background: transparent;
			}
			span {
				z-index: 800;
				top: 27px;
				right: 27px;
				height: 22px;
				width: 22px;
				position: absolute;
				border-radius: 50px;
				background-color: white;
				line-height: 23px;
				text-align: center;
				color: #4b4b4b;
				font-size: 17px;
				font-weight: 600;
			}
		}
	}

	.cart-counter.inCart {
		opacity: 1;
		transform: scale(1);
		-webkit-transition: all 0.3s ease-out;
		-moz-transition: all 0.3s ease-out;
		-o-transition: all 0.3s ease-out;
		-ms-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
	}

	.open .background-nav-list {
		width: 100%;
		background-color: rgba(255, 255, 255, 0.6);
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		z-index: 800;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		height: 510vh;
	}

	.submenu-div {
		//скорость закрытия подкатегорий в моб версии
		-webkit-transition: all 0.15s ease-in-out;
		-moz-transition: all 0.15s ease-in-out;
		-o-transition: all 0.15s ease-in-out;
		transition: all 0.15s ease-in-out;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		max-height: 0;

		.sub-item .active {
			pointer-events: none;
		}

		.sub-item {
			//ДОЧЕРНЯЯ КАТЕГОРИЯ ЦВЕТ
			-webkit-transition: all 0.15s ease-in-out;
			-moz-transition: all 0.15s ease-in-out;
			-o-transition: all 0.15s ease-in-out;
			transition: all 0.15s ease-in-out;
			opacity: 0.3;
			background-color: @child-a-mobile;

			width: 100%;

			a {
				font-weight: 500;
				width: 100%;
				padding: 1rem 0 1rem 5.5rem;
				position: relative;
				letter-spacing: 1px;
				font-size: 20px;
				color: #3c3c3c;
				-webkit-transition: all 0.15s ease-in-out;
				-moz-transition: all 0.15s ease-in-out;
				-o-transition: all 0.15s ease-in-out;
				transition: all 0.15s ease-in-out;
			}
		}
	}

	.navigation .navigation-list li {
		position: relative;
		&::after {
			content: '  ';
			display: block;
			position: absolute;
			bottom: 0;
			width: 4px;
			height: 1.2rem;
			left: -1px;
			z-index: 999;
			top: 2.9rem;
			margin-left: auto;
			margin-right: auto;
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			-ms-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			text-align: center;
			background-color: white;
			transform: scaleY(0) translateX(-1rem);
		}

		&:hover {
			.submenu-div {
				//скорость ОТКРЫТИЯ подкатегорий в моб версии
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
				z-index: 899;
				width: 100%;
				max-height: 70rem;

				.sub-item {
					-webkit-transition: all 0.3s ease-in-out;
					-moz-transition: all 0.3s ease-in-out;
					-o-transition: all 0.3s ease-in-out;
					transition: all 0.3s ease-in-out;
					//ДОЧЕРНЯЯ КАТЕГОРИЯ ЦВЕТ
					opacity: 1;
					background-color: @child-a-mobile;

					&:hover {
						background-color: @hover-submenu;
					}
				}
			}
		}
	}

	.navigation {
		height: 0.1rem;
		background-color: white;
		margin-top: 0;

		li {
			&:hover {
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
				background-color: @parent-a-active;
			}
		}

		.navigation-list .active {
			.parent-a {
				//РОДИТЕЛЬСКАЯ КАТЕГОРИЯ ЦВЕТ
				background-color: @parent-a-mobile-active;
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
			}
			&::after {
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
				transform: scaleY(2);
			}
		}
	}

	.mobile-nav {
		height: @mobileBarSize;
		box-shadow: 0 0 3rem rgba(0, 0, 0, 0.5);
		background-color: transparent;
		display: block;
		position: fixed;
		width: 100%;
		margin-right: 0;
		right: 0;
		top: 0;
	}

	nav .navigation-list {
		opacity: 0;
		transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;

		z-index: 900;
		overflow-y: scroll;
		transform: translateX(200%);
		background-color: @main-theme-color-mobile;
		width: 100%;
		height: 150vh;
		top: 0;
		bottom: 0;
		padding-bottom: 70vh;
		position: fixed;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 3rem rgba(0, 0, 0, 0.5);

		padding-top: 7rem;

		.parent-a {
			font-size: 20px;
			letter-spacing: 2px;
			font-weight: 500;
			z-index: 997;
			width: 100%;
			padding: 1.5rem 0 1.5rem 4rem;
		}

		.cart-item {
			transition: all 0.15s;
			transform: scale(0);
			background-color: white;
			opacity: 0;
			margin-top: 2rem;

			.cart-a {
				display: flex;
				flex-direction: row;
				padding: 10px 0 10px 40px;

				.icon {
					line-height: 60px;
					color: #464646;
					font-size: 3rem;
				}

				.counter {
					position: absolute;
					margin: auto;
					height: 18px;
					width: 16px;
					bottom: 40px;
					left: 53px;
					border-radius: 10px 10px 10px 10px;
					background-color: white;

					p {
						-webkit-transition: all 0.2s ease-in-out;
						-moz-transition: all 0.2s ease-in-out;
						-o-transition: all 0.2s ease-in-out;
						transition: all 0.2s ease-in-out;
						position: absolute;
						color: #343434;
						font-weight: bold;
						height: 15px;
						left: 3px;
						line-height: 18px;
						margin: 0;
						padding: 0;
						font-size: 15px;
					}
				}

				span {
					font-weight: 500;
					font-size: 20px;
					color: #343434;
					margin-left: 25px;
				}

				.to-cart-i {
					margin: 1px 0 0 25px;
					font-size: 35px;
					color: #343434;
				}
			}
			&:hover {
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;

				.counter {
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					background-color: #e4e4e4;
				}
			}
		}

		.inCart {
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			-ms-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			transform: scale(1);
			opacity: 1;
		}

		.connect-nav {
			display: flex;
			flex-direction: row;
			padding: 2rem 0rem 5rem 2rem;
			max-width: 25rem;

			a {
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				-ms-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				height: 45px !important;
				width: 45px !important;
				border-radius: 50px;
				position: relative;
				margin-left: 1.5rem;

				i {
					position: absolute;
					top: 7px;
					left: -7px;
					font-size: 30px;
					height: 60px !important;
					width: 60px !important;
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					border-radius: 50px;
				}

				&:hover {
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					transform: scale(1.1);
				}
			}
		}
	}

	nav .open .navigation-list {
		opacity: 1;
		transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;

		z-index: 980;
		// отвечает за то, насколько раскроется меню
		transform: translateX(25%);
		position: fixed;
		display: flex;
		flex-direction: column;
		width: 100%;

		&:hover {
			a {
				z-index: 981;
				width: 100%;
			}
		}
	}

	/*========================КНОПКА БУРГЕР МЕНЮ==============================*/
	.menu-mobile-container {
		width: 4.5rem;
		height: 4.5rem;
		top: 1.4rem;
		right: 1.4rem;
		z-index: 990;
		background-color: transparent;
		display: block;
		position: fixed;
		border-radius: 5% 5% 5% 5%;
		transition: all 0.2s;
	}

	.menu-mobile-container.open {
		.menu-mobile-bar,
		.menu-mobile-bar::after {
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			-ms-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			background: white;
		}
	}

	.menu-mobile-bar,
	.menu-mobile-bar::after {
		box-shadow: 1px 1px 5px rgba(92, 81, 80, 0.22),
			1px 1px 5px rgba(255, 255, 255, 0.22);
		position: absolute;
		background: #2d2d2d;
		width: 3rem;
		height: 0.39rem;
		content: '';
		top: 1.3rem;
		left: 0.5rem;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.menu-mobile-bar::after {
		top: 1.5rem;
		height: 0.39rem;
		left: 0;
	}

	.active .menu-mobile-bar {
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		transform: translateY(0.65rem);
	}

	.active .menu-mobile-bar::after {
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		transform: translateY(-1.4rem);
	}
}

/*===========================================ШИРИНА от 600  и МЕНЬШЕ 800 ПИКСЕЛЕЙ==============================================*/
@media screen and (max-width: 800px) and (min-width: 601px) {
	.cart-counter {
		position: fixed;
		right: 3rem;
		bottom: 3rem;
		opacity: 0;
		transform: scale(0);
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		background-color: transparent;
		width: 35px;
		height: 20px;
		z-index: 700;
		border-radius: 50px;
		box-shadow: 0 0 55px black, 0 0 55px white;

		.cart-counter-container {
			border-radius: 50px;
			bottom: 25px;
			left: 15px;
			position: relative;
			box-shadow: 0 0 55px black;
			i {
				height: 80px;
				z-index: 750;
				top: 1px;
				right: 1px;
				color: #4b4b4b;
				font-size: 75px !important;
				position: absolute;
				background: transparent;
			}
			span {
				z-index: 800;
				top: 27px;
				right: 27px;
				height: 22px;
				width: 22px;
				position: absolute;
				border-radius: 50px;
				background-color: white;
				line-height: 23px;
				text-align: center;
				color: #4b4b4b;
				font-size: 17px;
				font-weight: 600;
			}
		}
	}

	.cart-counter.inCart {
		opacity: 1;
		transform: scale(1);
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.open .background-nav-list {
		width: 100%;
		background-color: rgba(255, 255, 255, 0.6);
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		z-index: 800;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		height: 510vh;
	}

	.submenu-div {
		//скорость закрытия подкатегорий в моб версии
		-webkit-transition: all 0.15s ease-in-out;
		-moz-transition: all 0.15s ease-in-out;
		-o-transition: all 0.15s ease-in-out;
		-ms-transition: all 0.15s ease-in-out;
		transition: all 0.15s ease-in-out;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		max-height: 0;

		.sub-item .active {
			pointer-events: none;
		}

		.sub-item {
			//ДОЧЕРНЯЯ КАТЕГОРИЯ ЦВЕТ
			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			-ms-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
			opacity: 0.3;
			background-color: @child-a-mobile;

			width: 100%;

			a {
				font-weight: 500;
				width: 100%;
				padding: 1rem 0 1rem 5.5rem;
				position: relative;
				letter-spacing: 1px;
				font-size: 20px;
				color: #3c3c3c;
				-webkit-transition: all 0.5s ease-in-out;
				-moz-transition: all 0.5s ease-in-out;
				-o-transition: all 0.5s ease-in-out;
				transition: all 0.5s ease-in-out;
			}
		}
	}

	.navigation .navigation-list li {
		position: relative;
		&::after {
			content: '  ';
			display: block;
			position: absolute;
			bottom: 0;
			width: 4px;
			height: 1.2rem;
			left: -1px;
			z-index: 999;
			top: 2.9rem;
			margin-left: auto;
			margin-right: auto;
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			-ms-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			text-align: center;
			background-color: white;
			transform: scaleY(0) translateX(-1rem);
		}

		&:hover {
			.submenu-div {
				//скорость ОТКРЫТИЯ подкатегорий в моб версии
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
				z-index: 899;
				width: 100%;
				max-height: 70rem;

				.sub-item {
					-webkit-transition: all 0.3s ease-in-out;
					-moz-transition: all 0.3s ease-in-out;
					-o-transition: all 0.3s ease-in-out;
					transition: all 0.3s ease-in-out;

					//ДОЧЕРНЯЯ КАТЕГОРИЯ ЦВЕТ
					opacity: 1;
					background-color: @child-a-mobile;

					&:hover {
						background-color: @hover-submenu;
					}
				}
			}
		}
	}

	.navigation {
		height: 0.1rem;
		background-color: white;
		margin-top: 0;

		li {
			&:hover {
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
				background-color: @parent-a-active;
			}
		}

		.navigation-list .active {
			.parent-a {
				//РОДИТЕЛЬСКАЯ КАТЕГОРИЯ ЦВЕТ
				background-color: @parent-a-mobile-active;
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
			}
			&::after {
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
				transform: scaleY(2);
			}
		}
	}

	.mobile-nav {
		height: @mobileBarSize;
		box-shadow: 0 0 3rem rgba(0, 0, 0, 0.5);
		background-color: transparent;
		display: block;
		position: fixed;
		width: 100%;
		margin-right: 0;
		right: 0;
		top: 0;
	}

	nav .navigation-list {
		opacity: 0;
		transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;

		z-index: 900;
		overflow-y: scroll;
		transform: translateX(200%);
		background-color: @main-theme-color-mobile;
		width: 100%;
		height: 150vh;
		top: 0;
		bottom: 0;
		padding-bottom: 70vh;
		position: fixed;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 3rem rgba(0, 0, 0, 0.5);

		padding-top: 7rem;

		.parent-a {
			font-size: 20px;
			letter-spacing: 2px;
			font-weight: 500;
			z-index: 997;
			width: 100%;
			padding: 1.5rem 0 1.5rem 4rem;
		}

		.cart-item {
			transition: all 0.15s;
			transform: scale(0);
			background-color: white;
			opacity: 0;
			margin-top: 2rem;

			.cart-a {
				display: flex;
				flex-direction: row;
				padding: 10px 0 10px 40px;

				.icon {
					line-height: 5.4rem;
					color: #464646;
					font-size: 3rem;
				}

				.counter {
					position: absolute;
					margin: auto;
					height: 1.5rem;
					width: 16px;
					bottom: 40px;
					left: 53px;
					border-radius: 10px 10px 10px 10px;
					background-color: white;

					p {
						-webkit-transition: all 0.2s ease-in-out;
						-moz-transition: all 0.2s ease-in-out;
						-o-transition: all 0.2s ease-in-out;
						transition: all 0.2s ease-in-out;
						position: absolute;
						color: #343434;
						font-weight: bold;
						height: 15px;
						left: 3px;
						line-height: 18px;
						margin: 0;
						padding: 0;
						font-size: 15px;
					}
				}

				span {
					font-weight: 500;
					font-size: 20px;
					color: #343434;
					margin-left: 25px;
				}

				.to-cart-i {
					margin: 1px 0 0 25px;
					font-size: 35px;
					color: #343434;
				}
			}
			&:hover {
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;

				.counter {
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					background-color: #e4e4e4;
				}
			}
		}

		.inCart {
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			-ms-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			transform: scale(1);
			opacity: 1;
		}

		.connect-nav {
			display: flex;
			flex-direction: row;
			padding: 4.5rem 0rem 2rem 1rem;
			max-width: 25rem;

			a {
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				-ms-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				height: 60px !important;
				width: 60px !important;
				border-radius: 50px;
				position: relative;
				margin-left: 2.5rem;

				i {
					position: absolute;
					top: 10px;
					left: 0;
					font-size: 40px;
					height: 60px !important;
					width: 60px !important;
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					border-radius: 50px;
				}

				&:hover {
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					transform: scale(1.1);
				}
			}
		}
	}

	nav .open .navigation-list {
		opacity: 1;
		transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		z-index: 980;
		// отвечает за то, насколько раскроется меню
		transform: translateX(45%);
		position: fixed;
		display: flex;
		flex-direction: column;
		width: 100%;

		&:hover {
			a {
				z-index: 981;
				width: 100%;
			}
		}
	}

	/*========================КНОПКА БУРГЕР МЕНЮ==============================*/
	.menu-mobile-container {
		width: 4.5rem;
		height: 4.5rem;
		top: 1.4rem;
		right: 1.4rem;
		z-index: 990;
		background-color: transparent;
		display: block;
		position: fixed;
		border-radius: 5% 5% 5% 5%;
		transition: all 0.2s;
	}

	.menu-mobile-container.open {
		.menu-mobile-bar,
		.menu-mobile-bar::after {
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			-ms-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			background: white;
		}
	}

	.menu-mobile-bar,
	.menu-mobile-bar::after {
		box-shadow: 1px 1px 5px rgba(92, 81, 80, 0.22),
			1px 1px 5px rgba(255, 255, 255, 0.22);
		position: absolute;
		background: #2d2d2d;
		width: 3rem;
		height: 0.39rem;
		content: '';
		top: 1.3rem;
		left: 0.5rem;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.menu-mobile-bar::after {
		top: 1.5rem;
		height: 0.39rem;
		left: 0;
	}

	.active .menu-mobile-bar {
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		transform: translateY(0.65rem);
	}

	.active .menu-mobile-bar::after {
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		transform: translateY(-1.4rem);
	}
}

/*===========================================ШИРИНА БОЛЬШЕ 800 ПИКСЕЛЕЙ==============================================*/
@media screen and (min-width: 801px) {
	.cart-counter {
		display: none;
	}

	.connect-nav {
		display: none;
	}

	.navigation {
		z-index: 900;
		box-shadow: 4px 2px 26px rgba(107, 103, 104, 0.48), 0 0 16px #ffffff;

		height: 4.5rem;
		/*ВЫСОТА ХЕДЕРА*/
		margin-top: 9rem;
		background-color: #fd5949;

		.navigation-list {
			height: 4.5rem;
			width: 90%;
			position: absolute;
			left: 5%;

			.item {
				position: relative;
				height: 4.5rem;
				width: 11rem;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				-ms-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				font-weight: 500;
				text-decoration: none;

				.parent-a {
					text-transform: uppercase;
					line-height: 4.5rem;
					height: 4.5rem;
					text-align: center;
					font-size: 17px;
					letter-spacing: 3px;
					font-weight: 500;
					color: white;
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
				}

				&::after {
					content: '  ';
					display: block;
					position: absolute;
					bottom: 0;
					width: 25px;
					height: 2%;
					border-radius: 50px;
					left: 0;
					right: 0;
					bottom: -0.1rem;
					margin-left: auto;
					margin-right: auto;
					color: @white;
					text-align: center;
					background-color: @white;
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					transform: scale(0) translateY(1.5rem);
				}

				&:hover {
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;

					&::after {
						-webkit-transition: all 0.2s ease-in-out;
						-moz-transition: all 0.2s ease-in-out;
						-o-transition: all 0.2s ease-in-out;
						-ms-transition: all 0.2s ease-in-out;
						transition: all 0.2s ease-in-out;
						transform: scaleX(2) scaleY(2.5) translateY(-0.3rem);
					}

					.parent-a {
						color: white;
						-webkit-transition: all 0.2s ease-in-out;
						-moz-transition: all 0.2s ease-in-out;
						-o-transition: all 0.2s ease-in-out;
						-ms-transition: all 0.2s ease-in-out;
						transition: all 0.2s ease-in-out;
						background-color: #f05747;
					}

					.submenu-div {
						opacity: 1;
						-webkit-transition: all 0.2s ease-in-out;
						-moz-transition: all 0.2s ease-in-out;
						-o-transition: all 0.2s ease-in-out;
						-ms-transition: all 0.2s ease-in-out;
						transition: all 0.2s ease-in-out;
						position: absolute;
						display: flex;
						flex-direction: column;
						max-height: 70rem;
					}
				}
			}

			.cart-item {
				height: 4.5rem;
				position: absolute;
				right: 15%;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				-ms-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				transform: scale(0);
				background-color: white;
				opacity: 0;
				overflow: hidden;

				a {
					line-height: 4.5rem;
					height: 4.5rem;
					position: relative;
					width: 6rem;
					padding-left: 1.4rem;

					.icon {
						line-height: 4.5rem;
						color: #464646;
						font-size: 3rem;
					}

					.counter {
						-webkit-transition: all 0.35s ease-in-out;
						-moz-transition: all 0.35s ease-in-out;
						-o-transition: all 0.35s ease-in-out;
						-ms-transition: all 0.35s ease-in-out;
						transition: all 0.35s ease-in-out;
						position: absolute;

						margin: auto;
						height: 18px;
						width: 18px;
						bottom: 54%;
						left: 2.3rem;
						border-radius: 50px;
						background-color: white;

						p {
							-webkit-transition: all 0.2s ease-out;
							-moz-transition: all 0.2s ease-out;
							-o-transition: all 0.2s ease-out;
							-ms-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
							position: absolute;
							color: #343434;
							font-weight: bold;
							height: 15px;
							left: 4px;
							line-height: 18px;
							margin: 0;
							padding: 0;
							font-size: 15px;
						}
					}
				}
				&:hover {
					-webkit-transition: all 0.2s ease-out;
					-moz-transition: all 0.2s ease-out;
					-o-transition: all 0.2s ease-out;
					-ms-transition: all 0.2s ease-out;
					transition: all 0.2s ease-out;
					.counter {
						-webkit-transition: all 0.26s ease-out;
						-moz-transition: all 0.26s ease-out;
						-o-transition: all 0.26s ease-out;
						-ms-transition: all 0.26s ease-out;
						transition: all 0.26s ease-out;
						background-color: #e4e4e4;
						transform: rotate(360deg);
					}
				}
			}

			.inCart {
				-webkit-transition: all 0.32s ease-out;
				-moz-transition: all 0.32s ease-out;
				-o-transition: all 0.32s ease-out;
				-ms-transition: all 0.32s ease-out;
				transition: all 0.32s ease-out;
				transform: scale(1);
				opacity: 1;
			}
		}
	}

	.navigation .navigation-list .active {
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;

		.parent-a {
			color: @white;
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			-ms-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
		}
		&::after {
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			-ms-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			transform: scaleY(3) scaleX(0.9) translateY(-0.02rem)
				translateX(-5%);
		}
	}

	//выпадающее меню
	.submenu-div {
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		opacity: 0;
		z-index: 995;
		width: 15rem;
		background-color: white;
		position: absolute;
		overflow: hidden;
		max-height: 0;
		box-shadow: 0 10px 6rem rgba(0, 0, 0, 0.5);

		.sub-item.active {
			pointer-events: none;

			&::after {
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;

				content: '  ';
				display: block;
				position: absolute;

				width: 4px;
				border-radius: 50px;
				height: 0.2rem;
				left: -1px;
				z-index: 999;
				top: 2.3rem;
				background-color: #343434;
			}
		}
		.sub-item {
			position: relative;
			width: 100%;

			a {
				padding-left: 3rem;
				position: relative;
				width: 100%;
				font-weight: 500;
				font-size: 17px;
				letter-spacing: 1px;
				color: #343434;
			}
		}
	}

	.mobile-nav {
		display: none;
	}
}
</style>
