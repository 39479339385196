<template>
    <div>

        <div class="app-admin-layout ">

            <AdminNavBar @click="isOpen = !isOpen" />

            <AdminSideBar v-model="isOpen" />

                     <main class="app-content" :class="{full: !isOpen}">
<!--            <main class="app-content " :class="{full : !(windowSize && isOpen)}">-->
                <div class="app-page ">
                    <router-view/>
                </div>
            </main>


        </div>
    </div>
</template>

<script>
    import AdminNavBar from '../components/app/AdminNavBar';
    import AdminSideBar from '../components/app/AdminSideBar';

    export default {
        name: 'admin-layout',
        data: () => ({
            loading: true,
            isOpen: false,
            windowWidth: window.innerWidth
        }),

        components: {
            AdminNavBar,  AdminSideBar
        },
        computed : {
            windowSize(){
                if (this.windowWidth > 502){
                    return true
                } else {
                    return false
                }
            }
        }
    }
</script>

<style lang="less">
    .app-content{
        margin-top: 3rem;
        padding: 1rem;
    }
</style>